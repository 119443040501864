<template>
  <div :class="'mb-' + top">
    <input
      type="file"
      id="files"
      ref="files"
      class="excel-upload-input"
      :multiple="false"
      v-on:change="select_file"
      :accept="accept"
    />

    <v-icon
      color="primary"
      @click="
        showdialog = true;
        $refs.form.resetValidation();
      "
      :disabled="isProcessing || !can_upload"
      :loading="isProcessing"
      v-if="sh_upload"
    >
      mdi-upload</v-icon
    >

    <v-icon
      color="green"
      @click="showFile()"
      :disabled="
        isProcessing ||
        !(can_preview && item[field] && (ispreview || myfile.size == 0))
      "
      v-if="sh_preview && (ispreview || myfile.size == 0)"
      :loading="isProcessing"
    >
      mdi-eye</v-icon
    >

    <v-icon
      color="purple"
      @click="downloadFile()"
      :disabled="isProcessing || !(can_download && myfile.size > 0)"
      v-if="!ispreview && myfile.size > 0 && sh_download"
    >
      mdi-download</v-icon
    >

    <v-icon
      color="red"
      @click="deleteFile()"
      :disabled="isProcessing || !(can_delete && item[field])"
      v-if="sh_delete"
    >
      mdi-delete</v-icon
    >

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <!-- File Upload Progress Component -->
    <FileUploadProgress
      :isProcessing="isProcessing"
      :uploading="uploading"
      :progress="progress_value"
      :key="pl"
    />
    <confirmdialog ref="confirm" />
    <v-form ref="form" lazy-validation>
      <v-dialog
        v-model="showdialog"
        max-width="1000px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="showdialog = false"
      >
        <v-card>
          <v-card-title>
            Ajouter un {{ isurl ? " lien" : " fichier" }}
            <v-spacer></v-spacer>
            <v-checkbox
              v-model="isurl"
              label="Lien "
              class="mt-1"
              v-if="upload_type == 'uf'"
            ></v-checkbox>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" :sm="!isurl ? 11 : 12" :md="!isurl ? 11 : 12">
                <v-text-field
                  autocomplete="off"
                  dense
                  v-model="filename"
                  :label="isurl ? 'Adresse' : 'Nom de fichier'"
                  @input="validateUrl"
                  :rules="[
                    (v) =>
                      !!v ||
                      (isurl ? 'Adresse' : 'Nom de fichier ') + ' obligatoire',
                    (v) => !v || !isurl || isValid || 'Lien invalide',
                  ]"
                  @focus="$event.target.select()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" v-if="!isurl">
                <v-btn text @click="addFiles()" class="mt-1">
                  <v-icon> mdi-paperclip </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <div class="d-flex">
              <v-btn
                text
                color="primary"
                @click="saveUrl()"
                :disabled="isProcessing"
              >
                Enregistrer
                <v-icon> mdi-content-save-move-outline</v-icon>
              </v-btn>
              <v-btn text color="primary" @click="showdialog = false">
                Fermer
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>

<script>
let ends = [];
import UPLOAD from "../graphql/UPLOAD.gql";
import JSZip from "jszip";
import { api } from "print/data.js";

export default {
  name: "Download",
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    FileUploadProgress: () => import("./FileUploadProgress.vue"),
  },
  props: {
    item: Object,
    field: String,
    can_upload: Boolean,
    can_preview: Boolean,
    can_download: Boolean,
    can_delete: Boolean,
    box_filter: String,
    update_query: Object,
    var_name: String,
    upload_type: { type: String, default: "uf" },
    top: { type: Number, default: 0 },
    sh_upload: { type: Boolean, default: true },
    sh_download: { type: Boolean, default: true },
    sh_preview: { type: Boolean, default: true },
    sh_delete: { type: Boolean, default: true },
    max_size: Number,
  },
  data: () => ({
    showdialog: false,
    isValid: false,
    chosenFiles: [],
    accept: "",
    filename: null,
    isurl: false,
    size: null,
    snackbar: false,
    snackbar_timeout: 5000,
    snackbar_text: "",
    snackbar_color: "primary",
    isProcessing: false,
    uploading: true,
    progress_value: 0,
    pl: 10,
  }),
  computed: {
    myfile() {
      return this.item[this.field] ? JSON.parse(this.item[this.field]) : {};
    },
    label_file() {
      return this.myfile.size == 0 ? "Lien" : "Fichier";
    },
    ispreview() {
      let ok = false;
      let mf = this.item[this.field] ? JSON.parse(this.item[this.field]) : {};
      if (mf)
        if (mf.filename) {
          for (let index = 0; index < ends.length; index++) {
            const element = ends[index];
            if (
              mf.filename.toLowerCase().endsWith(element.ext) &&
              element.preview == 1
            ) {
              ok = true;
              break;
            }
          }
        }
      return ok;
    },
  },
  created() {
    if (this.box_filter) this.accept = this.box_filter;
    else if (this.$store.state.extensions) {
      this.$store.state.extensions.forEach((element) => {
        if (this.accept == "") this.accept = "." + element.ext;
        else this.accept = this.accept + ",." + element.ext;
      });
      ends = this.$store.state.extensions;
    }
    if (this.upload_type == "u") this.isurl = true;
  },
  methods: {
    async saveUrl() {
      if (this.isurl) {
        if (this.$refs.form.validate()) {
          let c = JSON.stringify({
            filename: this.filename,
            uploded_by: this.$store.state.me.id,
            uplode_date: this.$store.state.today,
            size: 0,
          });
          if (this.item.id > 0) {
            let r2 = await this.maj(this.update_query, {
              [this.var_name]: {
                id: this.item.id,
                [this.field]: c,
                write_uid: this.$store.state.me.id,
              },
            });
            if (r2) {
              this.$emit("file_uploaded", c, this.field);
              this.snackbar_text = "Lien ajoutée avec succes";
              this.snackbar_color = "success";
              this.snackbar = true;
              //this.$store.dispatch("Changed", true);
            }
          } else this.$emit("file_uploaded", c, this.field);
          this.showdialog = false;
        }
      } else await this.upload();
    },
    validateUrl() {
      const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      this.isValid = this.isurl ? pattern.test(this.filename) : true;
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      this.progress_value = 0;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
          onUploadProgress: (progressEvent) => {
            // Update progress
            this.progress_value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(`Upload Progress: ${this.progress}%`);
          },
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async up_file(content) {
      this.uploading = true;
      this.isProcessing = true; // Show the progress bar
      let r = await this.maj(UPLOAD, {
        file: content,
        filename: this.filename,
        size: this.size,
        c_size: content.size / 1000,
      });
      if (r) {
        //let d = JSON.parse(data.data.Upload);
        if (this.item.id > 0) {
          let r2 = await this.maj(this.update_query, {
            [this.var_name]: {
              id: this.item.id,
              [this.field]: r.Upload,
              write_uid: this.$store.state.me.id,
            },
          });
          if (r2) {
            this.$emit("file_uploaded", r.Upload, this.field);
            this.snackbar_text = "Fichier mis a jour avec succes";
            this.snackbar_color = "success";
            this.snackbar = true;
            // this.$store.dispatch("Changed", true);
          }
        } else this.$emit("file_uploaded", r.Upload, this.field);
        // Hide the progress bar on error
      }
      this.isProcessing = false;
    },
    async deleteFile() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Supprimer ce fichier!?",
          { color: "orange darken-3" }
        )
      ) {
        if (this.item.id > 0) {
          let r2 = await this.maj(this.update_query, {
            [this.var_name]: {
              id: this.item.id,
              [this.field]: null,
              write_uid: this.$store.state.me.id,
            },
          });
          if (r2) {
            this.snackbar_text = "Fichier supprimé avec succes";
            this.snackbar_color = "success";
            this.snackbar = true;
            this.$emit("file_deleted", this.field);
          }
        } else this.$emit("file_deleted", this.field);
      }
    },
    async upload() {
      if (this.chosenFiles) {
        for (let index = 0; index < this.chosenFiles.length; index++) {
          const element = this.chosenFiles[index];
          if (!this.progress && element.size > 0) {
            this.filename = element.name;
            this.size = element.size;
            this.progress = true;
            var zip = new JSZip();
            zip.file(element.name, element);
            let content = await zip.generateAsync({
              type: "blob",
              compression: "DEFLATE",
              compressionOptions: { level: 9 },
            });
            await this.up_file(content);
            this.filename = null;
            this.size = null;
            this.progress = false;
          }
        }
      }
    },

    async select_file() {
      if (this.$refs.files.files) {
        let ok = true;
        for (let index = 0; index < this.$refs.files.files.length; index++) {
          const element = this.$refs.files.files[index];
          if (
            element.size >
            this.$store.state.options[0].max_file_size * 1000000
          ) {
            this.snackbar_text =
              "Impossible d'ajouter le fichier " +
              element.name +
              " ! Taille fichier supperieure à " +
              this.$store.state.options[0].max_file_size +
              " Mb";
            this.snackbar_color = "error";
            this.snackbar = true;
            ok = false;
            break;
          }
          if (this.max_size) {
            if (element.size > this.max_size * 1000000) {
              this.snackbar_text =
                "Impossible d'ajouter le fichier " +
                element.name +
                " ! Taille fichier supperieure à " +
                this.max_size +
                " Mb";
              this.snackbar_color = "error";
              this.snackbar = true;
              ok = false;
              break;
            }
          }
        }
        if (ok) {
          this.chosenFiles = this.$refs.files.files;
          this.filename = this.$refs.files.files[0].name
            .split(".")
            .slice(0, -1)
            .join(".");
        }
      }
    },
    delchips(item) {
      let i = this.chosenFiles.findIndex((elm) => elm.name == item.name);
      if (i >= 0) {
        this.chosenFiles.splice(i, 1);
      }
    },
    addFiles() {
      this.$refs.files.click();
    },
    addUrl() {
      this.showdialog = true;
    },

    async showFile() {
      this.uploading = false;

      let file = JSON.parse(this.item[this.field]);
      if (file.size > 0) {
        this.isProcessing = true; // Set isProcessing to true at the start
        let xhr = new XMLHttpRequest();

        let s = file.c_size ? file.c_size * 1000 : file.size;

        xhr.open("GET", api + file.url, true);
        xhr.responseType = "blob";

        xhr.onprogress = function (pe) {
          this.progress_value = parseInt(Math.round((pe.loaded / s) * 100));
        }.bind(this);

        xhr.onload = async function () {
          if (xhr.status === 200) {
            const JSZip = require("jszip");
            try {
              const zip = await JSZip.loadAsync(xhr.response);
              const files = await Promise.all(
                Object.keys(zip.files).map((filename) =>
                  zip
                    .file(filename)
                    .async("nodebuffer")
                    .then((content) => ({
                      filename,
                      content,
                    }))
                )
              );

              files.forEach((file) => {
                let ok = false;
                let t;
                if (ends) {
                  for (let index = 0; index < ends.length; index++) {
                    const element = ends[index];
                    if (file.filename.toLowerCase().endsWith(element.ext)) {
                      ok = true;
                      t = element.app;
                      break;
                    }
                  }
                }
                if (ok) {
                  let blob = new Blob([file.content], { type: t });
                  // Open the PDF in a new window
                  const pdfContentUrl = URL.createObjectURL(blob);
                  window.open(
                    pdfContentUrl,
                    "_blank",
                    "toolbar=yes,scrollbars=yes,resizable=yes"
                  );
                }
              });
            } catch (error) {
              console.error("Error processing zip file:", error);
            } finally {
              this.isProcessing = false; // Ensure isProcessing is set to false after processing
            }
          } else {
            this.isProcessing = false; // Ensure isProcessing is set to false if the request fails
          }
        }.bind(this);

        xhr.onerror = function () {
          this.isProcessing = false; // Ensure isProcessing is set to false if an error occurs
          console.error("Error during file download.");
        }.bind(this);

        xhr.send();
      } else window.open(file.filename, "_blank");
    },
    async downloadFile() {
      this.uploading = false;

      if (
        await this.$refs.confirm.open("Confirmation", "Télecharger ce fichier?")
      ) {
        let xhr = new XMLHttpRequest();
        this.isProcessing = true; // Set isProcessing to true at the start
        let file = JSON.parse(this.item[this.field]);
        xhr.open("GET", api + file.url, true);
        xhr.responseType = "blob";
        let s = file.c_size ? file.c_size * 1000 : file.size;
        xhr.onprogress = function (pe) {
          this.progress_value = parseInt(Math.round((pe.loaded / s) * 100));
        }.bind(this);

        xhr.onload = function () {
          if (xhr.status === 200) {
            const JSZip = require("jszip");
            var zip = new JSZip();

            zip.loadAsync(xhr.response).then(
              function (contents) {
                let promises = Object.keys(contents.files).map(function (
                  filename
                ) {
                  return zip
                    .file(filename)
                    .async("nodebuffer")
                    .then(function (content) {
                      let blob = new Blob([content], {
                        type: "application/pdf",
                      });
                      let link = document.createElement("a");
                      link.href = window.URL.createObjectURL(blob);
                      link.download = file.filename;
                      link.click();
                    });
                });

                // Ensure isProcessing is set to false after all files are processed
                Promise.all(promises).then(() => {
                  this.isProcessing = false;
                });
              }.bind(this)
            );
          } else {
            this.isProcessing = false; // Ensure isProcessing is set to false if the request fails
          }
        }.bind(this);

        xhr.onerror = function () {
          this.isProcessing = false; // Ensure isProcessing is set to false if an error occurs
          console.error("Error during file download.");
        }.bind(this);

        xhr.send();
      }
    },
  },
};
</script>
<style scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
  margin: 0;
}
.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
</style>
